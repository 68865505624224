<template>
        
    <div class="row">
        <!-- Left Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">
            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient: </span>
                </div>
                <div class="col-md-7">
                  {{patient && patient.patient ? patient.patient.full_name : ""}}
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient ID: </span>
                </div>

                <div class="col-md-7">
                    <span> <strong>{{ patientId }}</strong> </span>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient Phone No: </span>      
                </div>

                <div class="col-md-7">
                    <span> <strong> {{patientMobile}} </strong> </span>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Select Admission info: </span>      
                </div>

                <div class="col-md-7">
                    {{patient ? patient.admission_no : ""}}
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Ward / Bed / Cabin: </span>      
                </div>

                <div class="col-md-7">
                    <span> <strong>
                        {{ patient && patient.service_resource ? patient.service_resource.name : "" }}
                    </strong> </span>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Consultant / Doctor: </span>
                </div>

                <div class="col-md-7">
                  <span> <strong> {{patient && patient.human_resource ? patient.human_resource.name : ""}} </strong> </span>
                </div>
            </div>

        </div>
        <div class="col-md-2"></div>
        <!-- Right Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">

          <div class="row h-40" v-if="isReturn">
            <div class="col-md-5 d-flex align-items-center">
              <span> Invoice Date: </span>
            </div>

            <div class="col-md-7">
              {{formData.invoice_date}}
            </div>
          </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span v-if="isReturn"> Return Date: </span>
                    <span v-else> Invoice Date: </span>
                </div>

                <div class="col-md-7">
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
            </div>

        </div>

        <div class="mt-2 col-md-5">
            <AsyncSelect
                placeholder="Select Product"
                v-model="productId"
                :api-service="fetchProductList"
                :reduce="name => name.id"
                :format-label="option => option.text"
                :additional-query="additionalProductQuery"
                label="text"
                :disabled="isReturn"
            />
        </div>

    </div>

</template>

<script setup>
import {computed, inject, onMounted, reactive, ref, watch} from 'vue'
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleContact from '@/services/modules/contact'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleInventory from "@/services/modules/inventory";
import handleHospital from '@/services/modules/hospital'

const props = defineProps({
  addNewItem: {
    type: Function
  },
  products: {
    type: Array,
    default: []
  },
  tableItems: {
    type: Array
  },
  patient: {
    type: Object
  },
  isReturn: {
    type: Boolean,
    default: false
  }
})

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const formData = inject('formData');
const showError = inject('showError')
let loader =ref(false);
const productId = ref(null);
const additionalQuery = {
    type: "patient"
}
const additionalProductQuery = {
    product_type: "services"
}
const doctors = reactive([]);

const { fetchServiceList } = handleHospital();
const {fetchProductList} = handlePurchase();
const {getProduct} = handleInventory();
const {fetchContactProfiles} = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();

//computed
const patientMobile = computed(() => {
    if ( props.patient && props.patient.patient ) {
        return props.patient.patient.mobile_no
    }
    return null
})

const patientId = computed(() => {
    if ( props.patient && props.patient.patient ) {
        return props.patient.patient.serial_no
    }
    return null
})

onMounted(async () => {
    formData.value.date =  new Date().toISOString().split('T')[0];

})

watch(productId, async () => {
    const productResp = await getProduct({
        id: productId.value,
        company_id: companyId
    })
    if (!productResp.data) {
        showError("Something went wrong please try again");
        return;
    }
    let selected = productResp.data;
    let product = {
        name: selected.name
    }
    props.tableItems.push({
        id: selected.id,
        product_id: productId.value,
        name: selected.name,
        description: selected.description.unit.description,
        quantity: 1,
        rate: selected.description.sales_price,
        discount: 0,
        vat: selected.description.vat_rate,
        sub_total: 0,
        discount_amount: 0,
        discount_percent: 0,
        vat_amount: 0,
        total: 0,
        isEdit: false,
        product: product
    })
})

</script>

<style>
.select label{
    font-size: 14px;
}
.h-40{
    height: 40px;
    align-items: center;
}
</style>